
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "EditExam",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Update Exam",
        url: "exam/edit"
      },
    ]);
    const levels = reactive(["JS1", "JS2", "JS3", "SS1", "SS2", "SS3"]);
    const exam = reactive({
        id: "1",
        name: "Test Exam",
        limit: "30",
        date: "2020-08-17",
        time: "10:15",
        duration: "60",
        levels: [],
        status: false,
        questions: [
          {
            id: 1,
            question: "Which year did COVID start?",
            choice1: "2018",
            choice2: "2019",
            choice3: "2020",
            choice4: "2021",
            answer: "B",
            limit: 1,
            isMultiple: false,
            latexSrc: "http://example.com/url",
            latexTitle: "http://example.com/url",
            imageUrl: "http://example.com/url",
            imageAlt: "http://example.com/url",
          }
        ],
      });
    const name = ref("Exam");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, levels, exam };
  },
});
